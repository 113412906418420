<template>
  <van-tabs v-model="model.tab" @click="onClickTab" sticky swipeable>
    <van-tab title="安装" name="安装" >
      <van-form @submit="onSubmit">
        <van-cell-group inset>
          <van-field v-model="model.username" name="username" label="联系人" placeholder="联系人" :rules="[{ required: true, message: '请填写联系人' }]" />
          <van-field v-model="model.tel" name="tel" label="联系电话" placeholder="联系电话" type="number" :rules="[{ required: true, message: '请填写联系电话' },{ pattern: /^1[3456789]\d{9}$/, message: '手机号码格式错误！'}]" />
          <van-field v-model="model.area" readonly name="area" label="地区" placeholder="地区" :rules="[{ required: true, message: '请选择地区' }]" @click="showarea=true" />
          <van-popup v-model="showarea" round position="bottom" :style="{ height: '40%' }">
            <van-area :area-list="areaList" @cancel="showarea=false" @confirm="confirmarea" title="选择所在省市区" />
          </van-popup>
          <van-field v-model="model.address" name="address" label="详细地址" placeholder="详细地址" :rules="[{ required: true, message: '请填写详细地址' }]" />
          <van-field v-model="model.category" readonly name="category" label="产品类型" placeholder="产品类型" :rules="[{ required: true, message: '请选择产品类型' }]" @click="getcategory" />
          <van-popup v-model="showcategory" round position="bottom" :style="{ height: '40%' }">
            <van-picker title="产品类型" show-toolbar :columns="categorylist" @confirm="confirmcategory" @cancel="showcategory=false" />
          </van-popup>
          <van-field v-model="model.goodscode" name="goodscode" label="产品型号" placeholder="产品型号" :rules="[{ required: true, message: '请填写产品型号' }]" />
          <van-field v-model="model.goodsnumber" name="goodsnumber" label="机器编号" placeholder="机器编号" />
          <van-field v-model="model.buydate" readonly name="buydate" label="出厂日期" placeholder="出厂日期" :rules="[{ required: true, message: '请选择出厂日期' }]" @click="showbuydate=true" />
          <van-popup v-model="showbuydate" round position="bottom" :style="{ height: '40%' }">
            <van-datetime-picker type="date" title="选择年月日" @confirm="confirmbuydate" @cancel="showbuydate=false"  />
          </van-popup>
          <van-field v-model="model.type" name="type" style="visibility: hidden" />
          <van-field v-model="model.category_id" name="category_id" style="visibility: hidden" />
        </van-cell-group>
        <div style="margin: 16px;position: fixed; bottom:0;width: calc(100% - 32px); "><van-button round block type="danger" native-type="submit">提交</van-button></div>
        </van-form>
      </van-tab>
      <van-tab title="维修" name="维修">
        <van-form @submit="onSubmit">
        <van-cell-group inset>
		  <van-field v-model="model.username" name="username" label="联系人" placeholder="联系人" :rules="[{ required: true, message: '请填写联系人' }]" />
          <van-field v-model="model.tel" name="tel" label="联系电话" type="number" placeholder="联系电话" :rules="[{ required: true, message: '请填写联系电话' },{ pattern: /^1[3456789]\d{9}$/, message: '手机号码格式错误！'}]" />
          <van-field v-model="model.area" readonly name="area" label="地区" placeholder="地区" :rules="[{ required: true, message: '请选择地区' }]" @click="showarea=true" />
          <van-popup v-model="showarea" round position="bottom" :style="{ height: '40%' }">
            <van-area :area-list="areaList" @cancel="showarea=false" @confirm="confirmarea" title="选择所在省市区" />
          </van-popup>
          <van-field v-model="model.address" name="address" label="详细地址" placeholder="详细地址" :rules="[{ required: true, message: '请填写详细地址' }]" />
          <van-field v-model="model.category" readonly name="category" label="产品类型" placeholder="产品类型" :rules="[{ required: true, message: '请选择产品类型' }]" @click="getcategory" />
          <van-popup v-model="showcategory" round position="bottom" :style="{ height: '40%' }">
            <van-picker title="产品类型" show-toolbar :columns="categorylist" @confirm="confirmcategory" @cancel="showcategory=false" />
          </van-popup>
          <van-field v-model="model.goodscode" name="goodscode" label="产品型号" placeholder="产品型号" :rules="[{ required: true, message: '请填写产品型号' }]" />
          <van-field v-model="model.goodsnumber" name="goodsnumber" label="机器编号" placeholder="机器编号" />
          <van-field v-model="model.buydate" readonly name="buydate" label="出厂日期" placeholder="出厂日期" :rules="[{ required: true, message: '请选择出厂日期' }]" @click="showbuydate=true" />
          <van-popup v-model="showbuydate" round position="bottom" :style="{ height: '40%' }">
            <van-datetime-picker type="date" title="选择年月日" @confirm="confirmbuydate" @cancel="showbuydate=false"  />
          </van-popup>
          <van-field v-model="model.errortype" readonly name="errortype" label="故障类型" placeholder="故障类型" :rules="[{ required: true, message: '请选择故障类型' }]" @click="showerrortype=true" />
          <van-popup v-model="showerrortype" round position="bottom" :style="{ height: '40%' }">
            <van-picker title="故障类型" show-toolbar :columns="['硬件', '软件', '电器']" @confirm="confirmerrortype" @cancel="showerrortype=false" />
          </van-popup>
          <van-field v-model="model.errorinfo" name="errorinfo" rows="5" type="textarea" autosize label="故障描述" placeholder="故障描述" :rules="[{ required: true, message: '请填写故障描述' }]" />
          <van-field readonly name="errorimg" label="故障图片" />
          <van-uploader v-model="model.errorimg" :before-read="beforeRead" @oversize="onOversize" :max-size="3*1024*1024" :after-read="afterRead" multiple :max-count="5" />
          <van-field v-model="model.type" name="type" style="visibility: hidden" />
          <van-field v-model="model.category_id" name="category_id" style="visibility: hidden" />
        </van-cell-group>
        <div style="margin: 16px;position: fixed; bottom:0;width: calc(100% - 32px); "><van-button round block type="danger" native-type="submit">提交</van-button></div>
        </van-form>
      </van-tab>
      <van-tab title="移机" name="移机">
        <van-form @submit="onSubmit">
        <van-cell-group inset>
          <van-field v-model="model.username" name="username" label="联系人" placeholder="联系人" :rules="[{ required: true, message: '请填写联系人' }]" />
          <van-field v-model="model.tel" name="tel" label="联系电话" placeholder="联系电话" type="number" :rules="[{ required: true, message: '请填写联系电话' },{ pattern: /^1[3456789]\d{9}$/, message: '手机号码格式错误！'}]" />
          <van-field v-model="model.area" readonly name="area" label="原地区" placeholder="原地区" :rules="[{ required: true, message: '请选择原地区' }]" @click="showarea=true" />
          <van-popup v-model="showarea" round position="bottom" :style="{ height: '40%' }">
            <van-area :area-list="areaList" @cancel="showarea=false" @confirm="confirmarea" title="选择所在省市区" />
          </van-popup>
          <van-field v-model="model.address" name="address" label="原详细地址" placeholder="原详细地址" :rules="[{ required: true, message: '请填写原详细地址' }]" />
          <van-field v-model="model.newarea" readonly name="newarea" label="新地区" placeholder="新地区" :rules="[{ required: true, message: '请选择新地区' }]" @click="shownewarea=true" />
          <van-popup v-model="shownewarea" round position="bottom" :style="{ height: '40%' }">
            <van-area :area-list="areaList" @cancel="shownewarea=false" @confirm="confirmnewarea" title="选择所在省市区" />
          </van-popup>
          <van-field v-model="model.newaddress" name="newaddress" label="新详细地址" placeholder="新详细地址" :rules="[{ required: true, message: '请填写新详细地址' }]" />
          <van-field v-model="model.category" readonly name="category" label="产品类型" placeholder="产品类型" :rules="[{ required: true, message: '请选择产品类型' }]" @click="getcategory" />
          <van-popup v-model="showcategory" round position="bottom" :style="{ height: '40%' }">
            <van-picker title="产品类型" show-toolbar :columns="categorylist" @confirm="confirmcategory" @cancel="showcategory=false" />
          </van-popup>
          <van-field v-model="model.goodscode" name="goodscode" label="产品型号" placeholder="产品型号" :rules="[{ required: true, message: '请填写产品型号' }]" />
          <van-field v-model="model.goodsnumber" name="goodsnumber" label="机器编号" placeholder="机器编号" />
          <van-field v-model="model.buydate" readonly name="buydate" label="出厂日期" placeholder="出厂日期" :rules="[{ required: true, message: '请选择出厂日期' }]" @click="showbuydate=true" />
          <van-popup v-model="showbuydate" round position="bottom" :style="{ height: '40%' }">
            <van-datetime-picker type="date" title="选择年月日" @confirm="confirmbuydate" @cancel="showbuydate=false"  />
          </van-popup>
          <van-field v-model="model.type" name="type" style="visibility: hidden" />
          <van-field v-model="model.category_id" name="category_id" style="visibility: hidden" />
        </van-cell-group>
        <div style="margin: 16px;position: fixed; bottom:0;width: calc(100% - 32px); "><van-button round block type="danger" native-type="submit">提交</van-button></div>
        </van-form>
      </van-tab>
      <van-tab title="培训" name="培训">
        <van-form @submit="onSubmit">
        <van-cell-group inset>
          <van-field v-model="model.username" name="username" label="联系人" placeholder="联系人" :rules="[{ required: true, message: '请填写联系人' }]" />
          <van-field v-model="model.tel" name="tel" label="联系电话" placeholder="联系电话" type="number" :rules="[{ required: true, message: '请填写联系电话' },{ pattern: /^1[3456789]\d{9}$/, message: '手机号码格式错误！'}]" />
          <van-field v-model="model.area" readonly name="area" label="地区" placeholder="地区" :rules="[{ required: true, message: '请选择地区' }]" @click="showarea=true" />
          <van-popup v-model="showarea" round position="bottom" :style="{ height: '40%' }">
            <van-area :area-list="areaList" @cancel="showarea=false" @confirm="confirmarea" title="选择所在省市区" />
          </van-popup>
          <van-field v-model="model.address" name="address" label="详细地址" placeholder="详细地址" :rules="[{ required: true, message: '请填写详细地址' }]" />
          <van-field v-model="model.category" readonly name="category" label="产品类型" placeholder="产品类型" :rules="[{ required: true, message: '请选择产品类型' }]" @click="getcategory" />
          <van-popup v-model="showcategory" round position="bottom" :style="{ height: '40%' }">
            <van-picker title="产品类型" show-toolbar :columns="categorylist" @confirm="confirmcategory" @cancel="showcategory=false" />
          </van-popup>
          <van-field v-model="model.goodscode" name="goodscode" label="产品型号" placeholder="产品型号" :rules="[{ required: true, message: '请填写产品型号' }]" />
          <van-field v-model="model.goodsnumber" name="goodsnumber" label="机器编号" placeholder="机器编号" />
          <van-field v-model="model.buydate" readonly name="buydate" label="出厂日期" placeholder="出厂日期" :rules="[{ required: true, message: '请选择出厂日期' }]" @click="showbuydate=true" />
          <van-popup v-model="showbuydate" round position="bottom" :style="{ height: '40%' }">
            <van-datetime-picker type="date" title="选择年月日" @confirm="confirmbuydate" @cancel="showbuydate=false"  />
          </van-popup>
          <van-field v-model="model.type" name="type" style="visibility: hidden" />
          <van-field v-model="model.category_id" name="category_id" style="visibility: hidden" />
        </van-cell-group>
        <div style="margin: 16px;position: fixed; bottom:0;width: calc(100% - 32px); "><van-button round block type="danger" native-type="submit">提交</van-button></div>
        </van-form>
      </van-tab>
  </van-tabs>
</template>
<script>
import { add, category } from '@/api/aftersalse'
import { areaList } from '@vant/area-data'
export default {
  name: 'Aftersales',
  data() {
    return {
      showarea: false,
      shownewarea: false,
      areaList: areaList,
      showcategory: false,
      showbuydate: false,
      showerrortype: false,
      categorylist: [],
      loading: false,
	  imagelist: [],
      model: {
        tab: '',
        username: '',
        tel: '',
        area: '',
        address: '',
        goodscode: '',
        goodsnumber: '',
        buydate: '',
        type: '安装',
        category_id: '',
        errorinfo: '',
        errortype: '',
      }
    }
  },
  created() {
  },
  setup: {
  },
  methods: {
    add() {
      this.$toast.loading({
        message: '加载中...',
        duration: 0,
        forbidClick: true
      })
    },
	afterRead(file) {
		console.log(file)
    },
	
    onSubmit() {
	//console.log(this.model.errorimg)
      this.$dialog.confirm({
        message: '是否提交 ' + this.model.tab + ' 订单'
      }).then(() => {
        add(this.model).then(res => {
          if (res.data.code === 200) {
            this.$toast.success('提交成功')
			//alert(JSON.stringify(res.data))
            //this.model = this.$options.data().model
			this.$router.push({path:'/showinfo',query:{id:res.data.data.d,union:res.data.data.union}});
          } else {
            this.$toast.fail('提交失败')
          }
        })
      })
    },
    onClickTab(name) {
      this.model.type = name
	  //alert(this.model.type)
    },
    confirmbuydate(val) {
      const date = new Date(val)
      const y = date.getFullYear()
      let m = date.getMonth() + 1
      m = m < 10 ? ('0' + m) : m
      let d = date.getDate()
      d = d < 10 ? ('0' + d) : d
      const time = y + '-' + m + '-' + d
      this.model.buydate = time
      this.showbuydate = false
    },
    confirmarea(values) {
      this.model.area = values.filter(item => !!item).map(item => item.name).join(',')
      this.showarea = false
    },
    confirmnewarea(values) {
      this.model.newarea = values.filter(item => !!item).map(item => item.name).join(',')
      this.shownewarea = false
    },
    getcategory() {
      this.showcategory = true
      category().then(res => {
        this.categorylist = res.data.data
      }).catch(() => {})
    },
    confirmcategory(values) {
      this.model.category = values.text
      this.model.category_id = values.value
      this.showcategory = false
    },
    confirmerrortype(values) {
      this.model.errortype = values
      this.showerrortype = false
    },
    beforeRead(file) {
      if (file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'image/jpg') {
        return true
      }
      this.$toast.fail('请上传正确格式的图片！')
      return false
    },
    onOversize(file) {
      this.$toast.fail('图片大小不能超过3M！')
    }
  }
}
</script>

